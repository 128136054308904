p {
    margin: 0;
}

.home-wrapper {
    padding-top: 350px;
}

.intro-section {
    text-align: center;
    padding-bottom: 30px;
    color: white;
}

.intro-header {
    font-size: 3rem;
    text-transform: uppercase;
}

.intro-text {
    width: 90%;
    margin: auto;
    line-height: 1.5em;
    font-size: 1.5rem;
}

.overview-section {
    width: 100%;
    padding-bottom: 20px;
    text-align: center;
    border-top: solid 2px white;
    background-color: white;
}

.overview-header {
    font-size: 2.3rem;
    text-transform: uppercase;
}

.overview-image {
    width: 275px;
    height: 275px;
    display: block;
    margin: 20px auto;
}

.overview-paragraph {
    font-size: 1.2rem;
    line-height: 1.5;
    width: 90%;
    margin: auto;
}

.overview-paragraph-summary {
    font-size: 1.2rem;
    line-height: 1.5;
    width: 90%;
    margin: auto;
    text-align: left;
}

.overview-text {
    font-size: 20px;
    color: limegreen;
}

@media (min-width: 768px) {
    .overview-image {
        width: 375px;
        height: 375px;
    }

    .overview-paragraph-summary {
        font-size: 1.5rem;
      }
}
.projects-tile-wrapper {
    background-color: white;
    border-bottom: 2px solid #e2e2e2;

    h2 {
        margin-top: 0;
    }
}

.projects-tile {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 20px;
}

.projects-image {
    width: 100%;
    margin-bottom: 20px;

    img {
        width: 100%;
        border: solid 1px #e2e2e2;
    }
}

.projects-image:hover {
    filter: brightness(85%);
}

.project-short-description {
    margin-bottom: 20px;
}

.project-button {
    width: 175px;
    font-size: 20px;
    font-weight: bold;
    border: solid 2px black;
    background-color: white;
    cursor: pointer;
}

.project-button:hover {
    background-color: black;
    color: white;
}

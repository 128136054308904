.project-wrapper {
    background-color: white;
}

.project-image {
    width: 100%;
    margin-bottom: 20px;
    border-bottom: solid 1px #e2e2e2;
    filter: brightness(75%);

    img {
        width: 100%;
        border: solid 1px #e2e2e2;
    }
}

.project-page-image {
    width: 100%;
    margin-bottom: 20px;
    border-bottom: solid 1px #e2e2e2;
    filter: brightness(75%);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    img {
        width: 100%;
        border: solid 1px #e2e2e2;
    }
}

.project-tech {
    text-align: center
}

.project-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 20px;

    h1 {
        margin: 0;
        margin-bottom: 20px;
        font-size: 3rem;
    }

    h3 {
        margin: 0;
        margin-bottom: 10px;
    }

    a {
        margin-bottom: 20px;
        color: black;
    }

    a:hover {
        color: rgb(114, 112, 253);
    }
}

.project-description {
    text-align: left;
    width: 90%;
}

@media (min-width: 768px) {
    .projects-tile {
        padding: 20px 125px;
    }

    .projects-image {
        width: 75%;
    }

    .project-info {
        p {
            font-size: 1.5rem;
        }
    }

    .project-description {
        width: 75%;
    }

    .project-page-image {
        height: 700px;
    }
}
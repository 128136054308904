.skills-wrapper {
    padding-top: 150px;
}

.skills-header {
    text-align: center;
    color: white;
    border: solid 3px white;
    font-size: 2rem;
    width: 200px;
    margin: 30px auto;
}

.skills-intro-section {
    background-color: white;
}

.skills-intro-header {
    text-align: center;
    padding-top: 20px;
    font-size: 2.5rem;
    text-transform: uppercase;
}

.skills-intro-paragraph {
    width: 90%;
    margin: auto;
    line-height: 1.5em;
    text-align: center;
    font-size: 1rem;
    font-style: italic;
    padding-bottom: 20px;
}

hr {
    margin: 0;
    padding-bottom: 20px;
    background-color: white;
    border: none;
    border-top: 2px solid #e2e2e2;
}

.skills-technology-section {
    background-color: white;
}

.skills-list {
    margin: 0;
    padding-top: 20px;
    padding-bottom: 20px;

    li {
        padding-bottom: 10px;
    }
}

.skills-history-section {
    background-color: white;
    padding: 20px;

    h3 {
        margin: 0;
    }

    ul {
        margin: 0;
    }
}

@media (min-width: 768px) {
    .skills-wrapper {
        margin: 0 50px;
    }

    .skills-history-section {
        padding-left: 75px;
        padding-right: 75px;

        p,
        li {
            font-size: 1.25rem;
        }
    }

    .skills-list {
        padding-left: 100px;
        padding-right: 100px;
        font-size: 1.25rem;
    }
}

@media (min-width: 1024px) {
    .skills-wrapper {
        margin: 0 150px;
    }

    .skills-header {
        font-size: 3rem;
        width: 300px;
    }

    .skills-history-header {
        font-size: 1.5rem;
    }
}
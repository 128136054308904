.font-link {
  font-family: 'Ubuntu', sans-serif;
}

body,
html {
  padding: 0;
  margin: 0;
  font-family: 'Ubuntu', sans-serif;
}

.main-layout {
  background-image: url("../assets/images/mountain-background.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.header-nav {
  position: fixed;
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  height: 55px;
  margin-bottom: 25px;
  background-color: black;
  z-index: 1;

  a {
    color: white;
  }
  a:hover {
    color:aquamarine;
  }
}

.menu-button {
  position: none;
  top: 20px;
  right: 20px;
  width: 75px;
  height: 30px;
  z-index: 1;
}

#menu-button {
  position: fixed;
  top: 20px;
  right: 20px;
  width: 75px;
  height: 30px;
}

.main-wrapper {
  padding-top: 95px;
}

.main-footer {
  height: 200px;
  color: #717981;
  background-color: #f5f5f5;

  a {
    color: #717981;
  }
  a:hover {
    color: rgb(114, 112, 253);
}
}

.main-email {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 2rem;
  background-color: #f5f5f5;
  color: #717981;
}

.main-socials {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 2rem;
  border-top: 2px solid #e2e2e2;
  background-color: #f5f5f5;
}

@media (min-width: 768px) {
  .home-wrapper {
    margin: 0 50px;
  }
}

@media (min-width: 1024px) {
  .home-wrapper {
    margin: 0 150px;
  }
}

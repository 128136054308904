.projects-wrapper {
    padding-top: 150px;
}

.projects-header {
    text-align: center;
    color: white;
    border: solid 3px white;
    font-size: 2rem;
    width: 200px;
    margin: 30px auto;
}

.projects-intro-section {
    background-color: white;
}

.projects-intro-header {
    text-align: center;
    padding-top: 20px;
    font-size: 2rem;
    text-transform: uppercase;
}

.projects-intro-paragraph {
    width: 90%;
    margin: auto;
    line-height: 1.5em;
    text-align: center;
    font-size: 1rem;
    font-style: italic;
    padding-bottom: 20px;
}

hr {
    margin: 0;
    padding: 0;
    background-color: white;
    border: none;
    border-top: 2px solid #e2e2e2;
}

.projects-tile-wrapper {
    background-color: white;
    border-bottom: 2px solid #e2e2e2;

    h2 {
        margin-top: 0;
    }
}

@media (min-width: 768px) {
    .projects-wrapper {
        margin: 0 50px;
    }

    .projects-header {
        font-size: 3rem;
        width: 300px;
    }
}

@media (min-width: 1024px) {
    .projects-wrapper {
        margin: 0 150px;
    }
}